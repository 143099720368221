import React, {CSSProperties, useEffect, useState} from 'react';

function App() {
    const [online, setOnline] = useState(true);

    const div: CSSProperties = {
        overflowX: 'hidden',
        overflowY: 'auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    };

    const iframe: CSSProperties = {
        width: '100%',
        height: '100%',
        border: 'none',
    }

    useEffect(() => {
        window.addEventListener('online', handler);
        window.addEventListener('offline', handler);

        return () => {
            window.removeEventListener('online', handler);
            window.removeEventListener('offline', handler);
        }
    });

    const handler = (event: Event) => {
        setOnline(navigator.onLine);
    }

    let content = <div style={div}>
        <iframe name="Victorparts" src="https://victorparts.com/" style={iframe}></iframe>
    </div>

    if (online === false) {
        content = (
            <>
                <div className="py-12 bg-white">
                    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-center">
                            <div className=""><svg className="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"></path>
                            </svg></div>
                        </div>

                        <div className="mt-10">
                            <ul className="md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                                <li>
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-10">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                                                    <path fill="#ed2939" d="M0 0h640v480H0z"/>
                                                    <path fill="#fff" d="M0 0h426.7v480H0z"/>
                                                    <path fill="#002395" d="M0 0h213.3v480H0z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">Il n'y a
                                                actuellement pas de connexion Internet</h4>
                                            <p className="mt-2 text-base leading-6 text-gray-500">
                                                Une connexion Internet active est requise pour utiliser l'application
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-10 md:mt-0">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-10">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><path fill="#c60b1e" d="M0 0h640v480H0z"/><path fill="#ffc400" d="M0 120h640v240H0z"/></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">Actualmente no hay conexión a Internet</h4>
                                            <p className="mt-2 text-base leading-6 text-gray-500">
                                                Se requiere una conexión a Internet activa para usar la aplicación
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="mt-10 md:mt-0">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <div className="flex items-center justify-center w-10">
                                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480"><defs><clipPath id="a"><path fill-opacity=".7" d="M-85.3 0h682.6v512H-85.3z"/></clipPath></defs><g clip-path="url(#a)" transform="translate(80) scale(.94)"><g stroke-width="1pt"><path fill="#012169" d="M-256 0H768v512H-256z"/><path fill="#fff" d="M-256 0v57.2L653.5 512H768v-57.2L-141.5 0H-256zM768 0v57.2L-141.5 512H-256v-57.2L653.5 0H768z"/><path fill="#fff" d="M170.7 0v512h170.6V0H170.7zM-256 170.7v170.6H768V170.7H-256z"/><path fill="#c8102e" d="M-256 204.8v102.4H768V204.8H-256zM204.8 0v512h102.4V0H204.8zM-256 512L85.3 341.3h76.4L-179.7 512H-256zm0-512L85.3 170.7H9L-256 38.2V0zm606.4 170.7L691.7 0H768L426.7 170.7h-76.3zM768 512L426.7 341.3H503l265 132.5V512z"/></g></g></svg>
                                            </div>
                                        </div>
                                        <div className="ml-4">
                                            <h4 className="text-lg leading-6 font-medium text-gray-900">There is currently no internet connection</h4>
                                            <p className="mt-2 text-base leading-6 text-gray-500">
                                                An active internet connection is required to use the app
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>{content}</>
    );
}

export default App;
